import * as Type from './type';
import RPC from 'api';
import Ability from '../../../../ability';
import WebApp from '../../../../webapp';
import { resolve } from 'path';

export function updateData (data) {
    return {
        type: Type.UPDATE_CLASSROOM_DATA,
        data
    };
}
export function updateSlaveMode (data) {
    return {
        type:Type.UPDATE_CLASSROOM_SLAVEMODE,
        data
    };
}
const ClassType = 'ClassroomAnalys';
//支持的智能事件
export const getLinkEvent = (arr) => (dispatch, getState, resolve, reject) => {
    if (!WebApp.EventCaps) {
        resolve(false);
        return;
    }
    let ruleEventCaps = {};
    let linkEvent = null;
    RPC.EventManager.getEventLink({ 'code': arr }).then(params => {
        //所有智能事件联动并集
        params.name.forEach((ruleLink, index) => {
            ruleEventCaps[arr[index]] = { ...WebApp.EventCaps, ...WebApp.DefaultEvent };
            ruleLink.forEach((item, i) => {
                if (item === 'default') {
                    ruleEventCaps[arr[index]] = WebApp.EventCaps;
                } else {
                    ruleEventCaps[arr[index]][item] = true;
                }
            });
        });
        dispatch(updateData({ ruleEventCaps }));
        resolve(true);
    }).catch(err => {
        dispatch(updateData({ linkEvent: WebApp.EventCaps }));
        resolve(true);
    });
};
export const getTempRule = (pro) => (dispatch, getState, resolve, reject) => {
    RPC.VideoInAnalyse.getTemplateRule(pro).then(json => {
        let ruleTemp = json[ClassType];
        dispatch(updateData({ ruleTemp }));
        resolve(true);
    }).catch(err => {
        console.log(err);
        resolve(false);
    });
};
export const getRuleConfig = (cfgName, currentPlanID) => async (dispatch, getState, resolve, reject) => {
    let cfgData = null;
    if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1) {
        await RPC.intelliScheme.getConfig(currentPlanID, cfgName).then((json) => {
            cfgData = json.map(item => item.params.table);
        });
    } else {
        await RPC.ConfigManager.getConfig(cfgName).then((json) => {
            cfgData = json.map(item => item.params.table);
        });
    }
    dispatch(updateData({ cfgData }));
    resolve(true);
};
export const setRuleConfig = (cfgName, cfgData, currentPlanID) => async (dispatch, getState, resolve, reject) => {
    let err = false;
    if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1) {
        await RPC.intelliScheme.setConfig(currentPlanID, cfgName, cfgData).then(() => {
        }).catch(() => {
            err = true;
        });
    } else {
        await RPC.ConfigManager.setConfig(cfgName, cfgData).then(() => {
        }).catch(() => {
            err = true;
        });
    }
    if (!err) {
        resolve(true);
    } else {
        reject();
    }
};
export const getDefaultRuleConfig = (cfgName) => (dispatch, getState, resolve, reject) => {
    let cfgData = null;
    let err = false;
    RPC.ConfigManager.getDefault(cfgName).then((json) => {
        cfgData = json.map(item => item.params.table);
        resolve(cfgData);
    }).catch(() => {
        console.log(err);
        resolve(false);
    });
};
export const gotoPreset = (currentChannel, ptzId) => (dispatch, getState, resolve, reject) => {
    RPC.PTZManager('gotoPreset', currentChannel, {'index': ptzId}).then((json) => {
        resolve(true);
    }).catch(() => {
        reject();
    });
};
export const getSlaveMode = (schemeID) => (dispatch, getState, resolve, reject) => {
    RPC.intelliScheme.getSchemeInfo(schemeID).then((json) => {
        let slaveMode = !!(json.Infos[0].masterSlaveMode);
        dispatch(updateSlaveMode(slaveMode));
        resolve(json);
    }).catch(() => {
        reject();
    });
};